import React from "react"
import { connect } from 'react-redux'
import { fetchEntity, updateSolicitud } from '../actions'
import { getEntity, getSolicitud } from '../reducers'
import {
    Box,
    useColorModeValue,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    CircularProgress,
    Select,
    Textarea,
    HStack,
    Text
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'

class RequestView extends React.Component {

    getColor = () => useColorModeValue('white', 'gray.800')

    componentDidMount() {
        this.props.fetchEntity('financiamiento')
    }

    tiempo_espera = (inicio, fin) => {
        let inicioDate = new Date(inicio)
        let finDate = new Date(fin)
        let time = Math.abs(finDate - inicioDate)
        var ms = time % 1000
        time = (time - ms) / 1000
        var secs = time % 60
        time = (time - secs) / 60
        var mins = time % 60
        var hrs = (time - mins) / 60

        return hrs + ':' + mins + ':' + secs
    }

    render() {

        const { solicitud, financiamiento_estados, updateSolicitud } = this.props

        return (
            <Layout>
                <Box w={'full'} bg={this.getColor} rounded={'md'} m={3} p={6} >
                    <Tabs>
                        <TabList>
                            <Tab><Text>Datos del cliente</Text></Tab>
                            <Tab><Text>Datos de solicitud</Text></Tab>
                            {solicitud.nCarro === 0 ? undefined : <Tab><Text>Datos de vehículo</Text></Tab>}
                            <Tab><Text>Procesamiento de solicitud:</Text></Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <HStack>
                                    <FormControl>
                                        <FormLabel>Nombre del cliente: </FormLabel>
                                        <Input readOnly variant='flushed' value={`${solicitud.cNombres} ${solicitud.cNombres2 || ""} ${solicitud.cApellidoPaterno} ${solicitud.cApellidoMaterno}`} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Código del cliente: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.nCliente + solicitud.cCodigo} />
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl>
                                        <FormLabel>Télefono: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.cTelefono} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Correo: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.cEmail} />
                                    </FormControl>
                                </HStack>
                            </TabPanel>

                            <TabPanel>
                                <HStack>
                                    <FormControl>
                                        <FormLabel>N* Solicitud: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.id} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Tipo solicitud: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.cTipoSolicitud} />
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl>
                                        <FormLabel>Fecha de solicitud: </FormLabel>
                                        <Input readOnly variant='flushed' value={new Date(solicitud.dFechaCreacion).toISOString().split('T')[0]} />
                                    </FormControl>
                                    {solicitud.dFechaAprobacion !== null &&
                                        <React.Fragment>
                                            <FormControl>
                                                <FormLabel>Fecha de aprobación: </FormLabel>
                                                <Input readOnly variant='flushed' value={new Date(solicitud.dFechaAprobacion).toISOString().split('T')[0]} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Tiempo de respuesta: </FormLabel>
                                                <Input readOnly variant='flushed' value={this.tiempo_espera(solicitud.dFechaCreacion, solicitud.dFechaAprobacion)} />
                                            </FormControl>
                                        </React.Fragment>
                                    }
                                </HStack>

                                <HStack>
                                    <FormControl>
                                        <FormLabel>Prima deseada: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.nMontoPrima} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Cuota deseada: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.nMontoSolicitado} />
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl>
                                        <FormLabel>Ingresos: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.nIngresosMensuales} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Gastos: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.nGastosMensuales} />
                                    </FormControl>
                                </HStack>

                                {solicitud.cDecision !== null &&
                                    <FormControl>
                                        <FormLabel>Decisión del cliente: </FormLabel>
                                        <Input readOnly variant='flushed' value={solicitud.cDecision} />
                                    </FormControl>

                                }
                            </TabPanel>

                            {solicitud.nCarro === 0 ? undefined :
                                <TabPanel>
                                    <HStack>
                                        <FormControl>
                                            <FormLabel>Tipo Vehículo: </FormLabel>
                                            <Input readOnly variant='flushed' value={solicitud.cClase} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Marca: </FormLabel>
                                            <Input readOnly variant='flushed' value={solicitud.cMarca} />
                                        </FormControl>
                                    </HStack>
                                    <HStack>
                                        <FormControl>
                                            <FormLabel>Valor: </FormLabel>
                                            <Input readOnly variant='flushed' value={'$' + solicitud.nValor} />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Prima: </FormLabel>
                                            <Input readOnly variant='flushed' value={'$' + solicitud.nPrima} />
                                        </FormControl>
                                    </HStack>
                                </TabPanel>
                            }
                            <TabPanel>
                                <Formik
                                    initialValues={{
                                        etapa: solicitud.cEtapa || 'En Proceso',
                                        plazo: solicitud.cPlazo || '',
                                        montoOtorgado: solicitud.nMontoOtorgado || '',
                                        cuota: solicitud.nCuota || '',
                                        tasa_aprobada: solicitud.nTasaAprobada || '',
                                        socio_estrategico: solicitud.cSocioEstrategico || '',
                                        resultado: solicitud.cEstadoFinanciamiento || '',
                                        observaciones: solicitud.cObservacion || ''
                                    }}
                                    validate={values => FormValidator([
                                        { field: 'etapa', value: values.etapa, rules: ['required'] },
                                        { field: 'resultado', value: values.resultado, rules: ['required'] },
                                        { field: 'observaciones', value: values.observaciones, rules: ['required'] }
                                    ])}
                                    onSubmit={(values, { setSubmitting }) => {
                                        Promise.resolve(updateSolicitud({ ...values, id: solicitud.id }))
                                            .then(() => { setSubmitting(false) })
                                    }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                        <form onSubmit={handleSubmit}>
                                            
                                                <FormControl isInvalid={errors.montoOtorgado && touched.montoOtorgado}>
                                                    <FormLabel>Monto Otorgado</FormLabel>
                                                    <Input name={"montoOtorgado"} onChange={handleChange} onBlur={handleBlur} value={values.montoOtorgado} />
                                                    <FormErrorMessage>{errors.montoOtorgado}</FormErrorMessage>
                                                </FormControl>
                                            <HStack>
                                                <FormControl isInvalid={errors.plazo && touched.plazo}>
                                                    <FormLabel>Plazo</FormLabel>
                                                    <Select name={"plazo"} onChange={handleChange} onBlur={handleBlur} value={values.plazo} >
                                                        <option value={""}>Seleccione el plazo</option>
                                                        <option value={"6 MESES"}>6 MESES</option>
                                                        <option value={"12 MESES"}>12 MESES</option>
                                                        <option value={"18 MESES"}>18 MESES</option>
                                                        <option value={"24 MESES"}>24 MESES</option>
                                                        <option value={"36 MESES"}>36 MESES</option>
                                                        <option value={"48 MESES"}>48 MESES</option>
                                                        <option value={"60 MESES"}>60 MESES</option>
                                                    </Select>
                                                    <FormErrorMessage>{errors.plazo}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl isInvalid={errors.tasa_aprobada && touched.tasa_aprobada}>
                                                    <FormLabel>Tasa aprobada</FormLabel>
                                                    <Input name={"tasa_aprobada"} onChange={handleChange} onBlur={handleBlur} value={values.tasa_aprobada} />
                                                    <FormErrorMessage>{errors.tasa_aprobada}</FormErrorMessage>
                                                </FormControl>
                                            </HStack>
                                            <HStack>
                                                <FormControl isInvalid={errors.cuota && touched.cuota}>
                                                    <FormLabel>Cuota</FormLabel>
                                                    <Input name={"cuota"} onChange={handleChange} onBlur={handleBlur} value={values.cuota} />
                                                    <FormErrorMessage>{errors.cuota}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl isInvalid={errors.socio_estrategico && touched.socio_estrategico}>
                                                    <FormLabel>Socio Estrategico</FormLabel>
                                                    <Input name={"socio_estrategico"} onChange={handleChange} onBlur={handleBlur} value={values.socio_estrategico} />
                                                    <FormErrorMessage>{errors.socio_estrategico}</FormErrorMessage>
                                                </FormControl>
                                            </HStack>
                                            <FormControl isInvalid={errors.etapa && touched.etapa}>
                                                <FormLabel>Etapa</FormLabel>
                                                <Select name={"etapa"} onChange={handleChange} onBlur={handleBlur} value={values.etapa} >
                                                    <option value={"En Proceso"}>En Proceso</option>
                                                    <option value={"Revisión"}>Revisión</option>
                                                    <option value={"Resultado"}>Resultado</option>
                                                </Select>
                                                <FormErrorMessage>{errors.etapa}</FormErrorMessage>
                                            </FormControl>
                                            <FormControl isInvalid={errors.resultado && touched.resultado}>
                                                <FormLabel>Estado</FormLabel>
                                                <Select name={"resultado"} onChange={handleChange} onBlur={handleBlur} value={values.resultado} >
                                                    <option value={""}>Seleccione resultado</option>
                                                    {typeof financiamiento_estados !== 'undefined' && financiamiento_estados.filter(financiamiento_estado => {
                                                        let flag = 0
                                                        switch (values.etapa) {
                                                            case "En Proceso": if (financiamiento_estado.id === 1) flag = 1; break
                                                            case "Revisión": if (financiamiento_estado.id === 2 || financiamiento_estado.id === 3) flag = 1; break
                                                            default: if (financiamiento_estado.id > 3) flag = 1; break
                                                        }
                                                        return flag
                                                    }
                                                    ).map(financiamiento_estado =>
                                                        <option value={financiamiento_estado.id} key={financiamiento_estado.id}>{financiamiento_estado.nombre}</option>)
                                                    }
                                                </Select>
                                                <FormErrorMessage>{errors.resultado}</FormErrorMessage>
                                            </FormControl>
                                            <FormControl isInvalid={errors.observaciones && touched.observaciones}>
                                                <FormLabel>Observaciones</FormLabel>
                                                <Textarea name={"observaciones"} onChange={handleChange} onBlur={handleBlur} value={values.observaciones} />
                                                <FormErrorMessage>{errors.observaciones}</FormErrorMessage>
                                            </FormControl>
                                            <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                            </Button>
                                        </form>
                                    )}
                                </Formik>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                </Box >
            </Layout >
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    financiamiento_estados: getEntity(state, 'financiamiento'),
    solicitud: getSolicitud(state, ownProps.match.params.id)
})

const mapDispatchToProps = { fetchEntity, updateSolicitud }

export default connect(mapStateToProps, mapDispatchToProps)(RequestView)
import React from "react"
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { saveCar, fetchEntity, fetchEntityWithId } from '../actions'
import { getRedirect, getConfig, getEntity, getCars } from '../reducers'
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    CircularProgress,
    useColorModeValue,
    Textarea,
    HStack,
    Select,
    InputGroup,
    InputLeftElement,
    InputRightElement
} from '@chakra-ui/react'
import { Formik, useFormikContext } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'


const Modelo = (props) => {
    const { values: { marca }, touched, setFieldValue } = useFormikContext();
    const { fetchEntityWithId } = props

    React.useEffect(() => {
        if (marca !== "") fetchEntityWithId('modelo', marca)
    }, [marca, touched.marca, setFieldValue, fetchEntityWithId])

    return <div>{props.children}</div>
}


class CarCreate extends React.Component {

    componentDidMount() {
        this.props.fetchEntity('anno-vehiculo')
        this.props.fetchEntity('clase')
        this.props.fetchEntity('marca')
        this.props.fetchEntity('combustible')
        this.props.fetchEntity('transmision')
        this.props.fetchEntity('direccion')
    }

    getColor = () => useColorModeValue('white', 'gray.800')

    render() {

        const { saveCar, clases, marcas, modelos, annos, combustibles, transmisiones, direcciones, redirect, cars, fetchEntityWithId } = this.props
        return (
            <Layout>
                {redirect && <Redirect to={cars.length > 0 ? `/car/${cars[cars.length - 1].id}/edit` : "/car"} />}
                <Box w={'full'} bg={this.getColor} rounded={'md'} m={3} p={6} >
                    <Formik
                        initialValues={{
                            tipo_vehiculo: '',
                            marca: '',
                            annio: '',
                            modelo: '',
                            color: '',
                            recorrido: 0,
                            combustible: '',
                            motor: '',
                            transmision: '',
                            direccion: '',
                            traccion: '',
                            tapizado: '',
                            aire_acondicionado: false,
                            vidrio: '',
                            placa: '',
                            pasajeros: 0,
                            extras: '',
                            valor: 0,
                            prima: 0,
                            ingresos: 0,
                            gastos: 0,
                            cuota: 0,
                            lugar: '',
                            disponibilidad: '',
                            autolote: '',
                            destacado: false,
                        }}
                        validate={values => FormValidator([
                            { field: 'tipo_vehiculo', value: values.tipo_vehiculo, rules: ['required'], lang: 'Tipo de vehiculo' },
                            { field: 'marca', value: values.marca, rules: ['required'] },
                            { field: 'annio', value: values.annio, rules: ['required'], lang: 'Año' },
                            { field: 'modelo', value: values.modelo, rules: ['required'] },
                            { field: 'color', value: values.color, rules: ['required'] },
                            { field: 'recorrido', value: values.recorrido, rules: ['required'] },
                            { field: 'recorridoTipo', value: values.recorridoTipo, rules: ['required'] },
                            { field: 'combustible', value: values.combustible, rules: ['required'] },
                            { field: 'motor', value: values.motor, rules: ['required'] },
                            { field: 'transmision', value: values.transmision, rules: ['required'] },
                            { field: 'direccion', value: values.direccion, rules: ['required'] },
                            { field: 'traccion', value: values.traccion, rules: ['required'] },
                            { field: 'tapizado', value: values.tapizado, rules: ['required'] },
                            { field: 'vidrio', value: values.vidrio, rules: ['required'] },
                            { field: 'placa', value: values.placa, rules: ['required'] },
                            { field: 'pasajeros', value: values.pasajeros, rules: ['required'] },
                            { field: 'extras', value: values.extras, rules: ['required'] },
                            { field: 'valor', value: values.valor, rules: ['required'] },
                            { field: 'prima', value: values.prima, rules: ['required'] },
                            { field: 'cuota', value: values.cuota, rules: ['required'] },
                            { field: 'ingresos', value: values.ingresos, rules: ['required'] },
                            { field: 'gastos', value: values.gastos, rules: ['required'] },
                            { field: 'lugar', value: values.lugar, rules: ['required'] },
                            { field: 'autolote', value: values.autolote, rules: ['required'] },
                            { field: 'disponibilidad', value: values.disponibilidad, rules: ['required'] },
                        ])}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true)
                            Promise.resolve(saveCar(values))
                                .then(() => {
                                    setSubmitting(false)
                                    resetForm()
                                })
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit}>

                                <HStack>
                                    <FormControl isInvalid={errors.annio && touched.annio}>
                                        <FormLabel>Año de vehiculo</FormLabel>
                                        <Select type="text" name='annio' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.annio} >
                                            <option value={""}>Seleccione año del vehículo</option>
                                            {annos.map(anno =>
                                                <option value={anno.cNomCod} key={anno.nValor}>{anno.cNomCod}</option>
                                            )}
                                        </Select>
                                        <FormErrorMessage>{errors.annio}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.tipo_vehiculo && touched.tipo_vehiculo}>
                                        <FormLabel>Tipo de vehículo</FormLabel>
                                        <Select type={'text'} size="lg" name={"tipo_vehiculo"} onChange={handleChange} onBlur={handleBlur} value={values.tipo_vehiculo} >
                                            <option value={""}>Seleccione tipo de vehículo</option>
                                            {clases.map(clase =>
                                                <option value={clase.cNomcod} key={clase.nValor}>{clase.cNomcod}</option>
                                            )}
                                        </Select>
                                        <FormErrorMessage>{errors.tipo_vehiculo}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.marca && touched.marca}>
                                        <FormLabel>Marca</FormLabel>
                                        <Select type={'text'} size="lg" name={"marca"} onChange={handleChange} onBlur={handleBlur} value={values.marca} >
                                            <option value={""}>Seleccione marca</option>
                                            {marcas.map(marca =>
                                                <option value={marca.cNomCod} key={marca.nValor}>{marca.cNomCod}</option>
                                            )}
                                        </Select>
                                        <FormErrorMessage>{errors.marca}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.modelo && touched.modelo}>
                                        <FormLabel>Modelo</FormLabel>
                                        <Modelo fetchEntityWithId={fetchEntityWithId}>
                                            <Select type={'text'} size="lg" name={"modelo"} onChange={handleChange} onBlur={handleBlur} value={values.modelo} >
                                                <option value={""}>Seleccione modelo</option>
                                                {modelos.map(modelos =>
                                                    <option value={modelos.cDescModelo} key={modelos.nCodModVehiculo}>{modelos.cDescModelo}</option>
                                                )}
                                            </Select>
                                            <FormErrorMessage>{errors.modelo}</FormErrorMessage>
                                        </Modelo>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.placa && touched.placa}>
                                        <FormLabel>Número de placa</FormLabel>
                                        <Input type={'text'} size="lg" name={"placa"} min={0} onChange={handleChange} onBlur={handleBlur} value={values.placa} />
                                        <FormErrorMessage>{errors.placa}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.pasajeros && touched.pasajeros}>
                                        <FormLabel>Cantidad de pasajeros</FormLabel>
                                        <Input type={'number'} size="lg" name={"pasajeros"} min={0} onChange={handleChange} onBlur={handleBlur} value={values.pasajeros} />
                                        <FormErrorMessage>{errors.pasajeros}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.color && touched.color}>
                                        <FormLabel>Color</FormLabel>
                                        <Input type={'text'} size="lg" name={"color"} onChange={handleChange} onBlur={handleBlur} value={values.color} />
                                        <FormErrorMessage>{errors.color}</FormErrorMessage>
                                    </FormControl>


                                    <FormControl isInvalid={errors.recorrido && touched.recorrido}>
                                        <FormLabel>Kilometraje</FormLabel>
                                        <InputGroup>
                                            <Input type={'number'} size="lg" name={"recorrido"} onChange={handleChange} onBlur={handleBlur} value={values.recorrido} />
                                            <Select size="lg" name={"recorridoTipo"} onChange={handleChange} onBlur={handleBlur} value={values.recorridoTipo} >
                                                <option value={"Km"} >Km</option>
                                                <option value={"Mi"} >Mi</option>
                                            </Select>
                                        </InputGroup>
                                        <FormErrorMessage>{errors.recorrido}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.combustible && touched.combustible}>
                                        <FormLabel>Combustible</FormLabel>
                                        <Select type={'text'} size="lg" name={"combustible"} onChange={handleChange} onBlur={handleBlur} value={values.combustible} >
                                            <option value={""} >Seleccione combustible</option>
                                            {combustibles.map(combustible =>
                                                <option value={combustible.cNomcod} key={combustible.nValor}>{combustible.cNomcod}</option>
                                            )}
                                        </Select>
                                        <FormErrorMessage>{errors.combustible}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.motor && touched.motor}>
                                        <FormLabel>Cilindraje</FormLabel>
                                        <Input type={'text'} size="lg" name={"motor"} onChange={handleChange} onBlur={handleBlur} value={values.motor} />
                                        <FormErrorMessage>{errors.motor}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.transmision && touched.transmision}>
                                        <FormLabel>Transmisión</FormLabel>
                                        <Select size="lg" name={"transmision"} onChange={handleChange} onBlur={handleBlur} value={values.transmision} >
                                            <option value={""} >Seleccione transmisión</option>
                                            {transmisiones.map(transmision =>
                                                <option value={transmision.cNomCod} key={transmision.nValor}>{transmision.cNomCod}</option>
                                            )}
                                        </Select>
                                        <FormErrorMessage>{errors.transmision}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.direccion && touched.direccion}>
                                        <FormLabel>Dirección</FormLabel>
                                        <Select size="lg" name={"direccion"} onChange={handleChange} onBlur={handleBlur} value={values.direccion} >
                                            <option value={""} >Seleccione dirección</option>
                                            {direcciones.map(direccion =>
                                                <option value={direccion.cNomCod} key={direccion.nValor}>{direccion.cNomCod}</option>
                                            )}
                                        </Select>
                                        <FormErrorMessage>{errors.direccion}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.traccion && touched.traccion}>
                                        <FormLabel>Tracción</FormLabel>
                                        <Select name={"traccion"} onChange={handleChange} onBlur={handleBlur} value={values.traccion} >
                                            <option value={""} >Seleccione tracción</option>
                                            <option value={"AWD"}>AWD</option>
                                            <option value={"FWD"}>FWD</option>
                                            <option value={"RWD"}>RWD</option>
                                            <option value={"4x4"}>4x4</option>
                                            <option value={"4x2"}>4x2</option>
                                        </Select>
                                        <FormErrorMessage>{errors.traccion}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.tapizado && touched.tapizado}>
                                        <FormLabel>Tapizado</FormLabel>
                                        <Select name={"tapizado"} onChange={handleChange} onBlur={handleBlur} value={values.tapizado} >
                                            <option value={""} >Seleccione tapizado</option>
                                            <option value={"Cuero"}>Cuero</option>
                                            <option value={"Foamizada"}>Foamizada</option>
                                            <option value={"Alcántara"}>Alcántara</option>
                                            <option value={"Vinilo"}>Vinilo</option>
                                            <option value={"Tela"}>Tela</option>
                                            <option value={"Microfibra"}>Microfibra</option>
                                        </Select>
                                        <FormErrorMessage>{errors.tapizado}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.vidrio && touched.vidrio}>
                                        <FormLabel>Vidrios</FormLabel>
                                        <Select name={"vidrio"} onChange={handleChange} onBlur={handleBlur} value={values.vidrio} >
                                            <option value={""} >Seleccione vidrio</option>
                                            <option value={"Eléctrico"}>Eléctrico</option>
                                            <option value={"Manual"}>Manual</option>
                                        </Select>
                                        <FormErrorMessage>{errors.vidrio}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.aire_acondicionado && touched.aire_acondicionado}>
                                        <FormLabel>Aire Acondicionado</FormLabel>
                                        <Select name={"aire_acondicionado"} onChange={handleChange} onBlur={handleBlur} value={values.aire_acondicionado} >
                                            <option value={false}>No</option>
                                            <option value={true}>Si</option>
                                        </Select>
                                        <FormErrorMessage>{errors.aire_acondicionado}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <FormControl isInvalid={errors.lugar && touched.lugar}>
                                    <FormLabel>Zona residencial</FormLabel>
                                    <Select size="lg" name={"lugar"} onChange={handleChange} onBlur={handleBlur} value={values.lugar} >
                                        <option value={""}>Seleccione zona</option>
                                        <option value={"Zona central"}>Zona central</option>
                                        <option value={"Zona oriental"}>Zona oriental</option>
                                        <option value={"Zona occidental"}>Zona occidental</option>
                                    </Select>
                                    <FormErrorMessage>{errors.lugar}</FormErrorMessage>
                                </FormControl>

                                <HStack>

                                    <FormControl isInvalid={errors.disponibilidad && touched.disponibilidad}>
                                        <FormLabel>Disponibilidad</FormLabel>
                                        <Select name={"disponibilidad"} onChange={handleChange} onBlur={handleBlur} value={values.disponibilidad} >
                                            <option value={""} >Seleccione disponibilidad</option>
                                            <option value={"Disponible"}>Disponible</option>
                                            <option value={"No disponible"}>No disponible</option>
                                        </Select>
                                        <FormErrorMessage>{errors.disponibilidad}</FormErrorMessage>
                                    </FormControl>

                                    <FormControl isInvalid={errors.destacado && touched.destacado}>
                                        <FormLabel>Destacado</FormLabel>
                                        <Select name={"destacado"} onChange={handleChange} onBlur={handleBlur} value={values.destacado} >
                                            <option value={false}>Normal</option>
                                            <option value={true}>Destacado</option>
                                        </Select>
                                        <FormErrorMessage>{errors.destacado}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.valor && touched.valor}>
                                        <FormLabel>Valor</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1.2em'
                                                children='$'
                                            />
                                            <Input type={'number'} size="lg" name={"valor"} min={0} step={"0.01"} onChange={handleChange} onBlur={handleBlur} value={values.valor} />
                                        </InputGroup>
                                        <FormErrorMessage>{errors.valor}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.cuota && touched.cuota}>
                                        <FormLabel>Cuota aproximada</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1.2em'
                                                children='$'
                                            />
                                            <Input type={'number'} size="lg" name={"cuota"} min={0} step={"0.01"} onChange={handleChange} onBlur={handleBlur} value={values.cuota} />
                                        </InputGroup>
                                        <FormErrorMessage>{errors.cuota}</FormErrorMessage>
                                    </FormControl>

                                </HStack>

                                <HStack>

                                    <FormControl isInvalid={errors.ingresos && touched.ingresos}>
                                        <FormLabel>Ingresos</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1.2em'
                                                children='$'
                                            />
                                            <Input type={'number'} size="lg" name={"ingresos"} min={0} step={"0.01"} onChange={handleChange} onBlur={handleBlur} value={values.ingresos} />
                                        </InputGroup>
                                        <FormErrorMessage>{errors.ingresos}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.gastos && touched.gastos}>
                                        <FormLabel>gastos</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1.2em'
                                                children='$'
                                            />
                                            <Input type={'number'} size="lg" name={"gastos"} min={0} step={"0.01"} onChange={handleChange} onBlur={handleBlur} value={values.gastos} />
                                        </InputGroup>
                                        <FormErrorMessage>{errors.gastos}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <HStack>
                                    <FormControl isInvalid={errors.prima && touched.prima}>
                                        <FormLabel>Prima</FormLabel>
                                        <InputGroup>
                                            <Input type={'number'} size="lg" name={"prima"} min={0} step={"1"} max={100} onChange={handleChange} onBlur={handleBlur} value={values.prima} />
                                            <InputRightElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1.2em'
                                                children='%'
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{errors.prima}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.autolote && touched.autolote}>
                                        <FormLabel>Autolote</FormLabel>
                                        <Input type={'text'} size="lg" name={"autolote"} onChange={handleChange} onBlur={handleBlur} value={values.autolote} />
                                        <FormErrorMessage>{errors.autolote}</FormErrorMessage>
                                    </FormControl>
                                </HStack>

                                <FormControl isInvalid={errors.extras && touched.extras}>
                                    <FormLabel>Versión</FormLabel>
                                    <Textarea size="lg" name={"extras"} onChange={handleChange} onBlur={handleBlur} value={values.extras} />
                                    <FormErrorMessage>{errors.extras}</FormErrorMessage>
                                </FormControl>

                                <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                    {isSubmitting ? <CircularProgress /> : 'Crear'}
                                </Button>
                            </form>
                        )}
                    </Formik>
                </Box>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    redirect: getRedirect(state),
    config: getConfig(state),
    clases: getEntity(state, "clase"),
    marcas: getEntity(state, "marca"),
    modelos: getEntity(state, "modelo"),
    annos: getEntity(state, "anno-vehiculo"),
    combustibles: getEntity(state, "combustible"),
    transmisiones: getEntity(state, "transmision"),
    direcciones: getEntity(state, "direccion"),
    cars: getCars(state)
})

const mapDispatchToProps = { saveCar, fetchEntity, fetchEntityWithId }

export default connect(mapStateToProps, mapDispatchToProps)(CarCreate)

import React from "react"
import Layout from '../layout'
import { Flex, Heading, useColorMode} from '@chakra-ui/react'
import Logo from '../multimedia/logo.png'
import LogoDark from '../multimedia/logo2.png'

const Dashboard = (props) => {

    const { colorMode } = useColorMode()
    return (
        <Layout >
            <Flex justifyContent={"center"} alignItems={"center"} mt={"30px"} flexDir={"column"}> 
                <img src={colorMode === 'light' ? Logo : LogoDark} alt="logo" />
                <Heading mt={"30px"} >Administracion Easycar</Heading>
            </Flex>
        </Layout>
    )
}

export default Dashboard
import React from "react"
import { connect } from 'react-redux'
import { updateProfile, updateUserPassword, fetchRoles } from '../actions'
import {
    getUser,
    getAuth,
} from '../reducers'
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    Heading,
    CircularProgress,
    useColorModeValue,
    Select
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'

const Profile = (props) => {

    const { user, updateProfile, updateUserPassword } = props
    return (
        <Layout>

            <Box
                w={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                rounded={'md'}
                m={3}
                p={6}
            >
                <Heading>Datos personales </Heading>
                <Formik
                    initialValues={{ nombre: user.nombre, apellido: user.apellido, zona_residencial: user.zona }}
                    validate={values => FormValidator([
                        { field: 'nombre', value: values.nombre, rules: ['required'] },
                        { field: 'apellido', value: values.apellido, rules: ['required'] }
                    ])}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        Promise.resolve(updateProfile({ ...values, id: user.id }))
                            .then(() => {
                                setSubmitting(false)
                                resetForm()
                            })
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl isInvalid={errors.nombre && touched.nombre}>
                                <FormLabel>Nombre</FormLabel>
                                <Input type="text" name='nombre' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.nombre} />
                                <FormErrorMessage>{errors.nombre}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.apellido && touched.apellido}>
                                <FormLabel>Apellido</FormLabel>
                                <Input type={'text'} size="lg" name={"apellido"} onChange={handleChange} onBlur={handleBlur} value={values.apellido} />
                                <FormErrorMessage>{errors.apellido}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.zona_residencial && touched.zona_residencial}>
                                <FormLabel>Zona residencial</FormLabel>
                                <Select size="lg" name={"zona_residencial"} onChange={handleChange} onBlur={handleBlur} value={values.zona_residencial} >
                                    <option value={""}>Seleccione zona</option>
                                    <option value={"Zona central"}>Zona central</option>
                                    <option value={"Zona oriental"}>Zona oriental</option>
                                    <option value={"Zona occidental"}>Zona occidental</option>
                                </Select>
                                <FormErrorMessage>{errors.zona_residencial}</FormErrorMessage>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Correo</FormLabel>
                                <Input type={'text'} size="lg" value={user.email} disabled />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Rol</FormLabel>
                                <Input type={'text'} size="lg" value={user.rol_usuario} disabled />
                            </FormControl>
                            <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Actualizar'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>

            <Box
                w={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                rounded={'md'}
                m={3}
                p={6}
            >
                <Heading>Contraseña</Heading>
                <Formik
                    initialValues={{ password: '', oldpassword: '', repeatpassword: '' }}
                    validate={values => FormValidator([
                        { field: 'password', value: values.password, rules: ['required'], lang: 'Contraseña' },
                            { field: 'repeatpassword', value: values.repeatpassword, rules: ['required', 'same'], param: values.password, lang: 'repetir contraseña' },
                            { field: 'oldpassword', value: values.oldpassword, rules: ['required'], lang: 'Contraseña actual' }
                    ])}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        Promise.resolve(updateUserPassword({ ...values, id: user.id }))
                            .then(setSubmitting(false))
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl isInvalid={errors.oldpassword && touched.oldpassword}>
                                <FormLabel>Contraseña actual</FormLabel>
                                <Input type="password" name='oldpassword' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.oldpassword} />
                                <FormErrorMessage>{errors.oldpassword}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.password && touched.password}>
                                <FormLabel>Nueva contraseña</FormLabel>
                                <Input type={'password'} size="lg" name={"password"} onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                <FormErrorMessage>{errors.password}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.repeatpassword && touched.repeatpassword}>
                                <FormLabel>Repetir Contraseña</FormLabel>
                                <Input type="password" name='repeatpassword' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.repeatpassword} />
                                <FormErrorMessage>{errors.repeatpassword}</FormErrorMessage>
                            </FormControl>
                            <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                {isSubmitting ? <CircularProgress /> : 'Actualizar'}
                            </Button>
                        </form>
                    )}
                </Formik>
            </Box>
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    user: getUser(state, getAuth(state))
})

const mapDispatchToProps = { updateProfile, updateUserPassword, fetchRoles }

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
import React from "react"
import { connect } from 'react-redux'
import { updateClient, carImage, fetchClient, fetchEntity, fetchEntityWithId, fetchBandejaCliente, fetchSolicitudesCliente, updateBandejaCliente } from '../actions'
import { getBandejaUsuario, getClient, getConfig, getEntity, getSolicitudesCliente } from '../reducers'
import {
    Box,
    useColorModeValue,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Button,
    CircularProgress,
    Select,
    Textarea,
    HStack,
    Table, Thead, Tr, Th, Tbody, Td,
    Image,
    Text,
    Spinner
} from '@chakra-ui/react'
import { Formik, useFormikContext } from 'formik'
import { FormValidator } from '../utils/validationForms'
import Layout from '../layout'
import placeholder from '../multimedia/placeholder.jpg'
import { Link } from "react-router-dom"
import { APIpath } from '../actions'


const Municipio = (props) => {
    const { values: { departamento }, touched, setFieldValue } = useFormikContext();
    const { fetchEntityWithId } = props

    React.useEffect(() => {
        if (departamento !== "") fetchEntityWithId('municipio', departamento)
    }, [departamento, touched.departamento, setFieldValue, fetchEntityWithId])

    return <div>{props.children}</div>
};

const replaceImage = (error) => {
    error.target.src = placeholder;
}

class ClientEdit extends React.Component {

    getColor = () => useColorModeValue('white', 'gray.800')

    tiempo_espera = (inicio, fin) => {
        let inicioDate = new Date(inicio)
        let finDate = new Date(fin)
        let time = Math.abs(finDate - inicioDate)
        var ms = time % 1000
        time = (time - ms) / 1000
        var secs = time % 60
        time = (time - secs) / 60
        var mins = time % 60
        var hrs = (time - mins) / 60

        return hrs + ':' + mins + ':' + secs
    }

    componentDidMount() {
        this.props.fetchClient(this.props.match.params.id)
        this.props.fetchBandejaCliente(this.props.match.params.id)
        this.props.fetchSolicitudesCliente(this.props.match.params.id)
        this.props.fetchEntity('bandeja')
        this.props.fetchEntity('financiamiento')
    }

    render() {

        const {
            departamentos, municipios, client, bandeja, bandeja_acciones, solicitudes,
            updateClient, updateBandejaCliente, fetchEntityWithId, financiamiento_estados
        } = this.props

        return (
            <Layout>

                <Box w={'full'} bg={this.getColor} rounded={'md'} m={3} p={6} >
                    <Tabs>
                        <TabList>
                            <Tab>Datos del cliente</Tab>
                            <Tab>Solicitudes</Tab>
                            <Tab>Documentos</Tab>
                            <Tab>Acciones</Tab>
                        </TabList>

                        <TabPanels>

                            <TabPanel>

                                {Object.keys(client).length === 0 ?
                                    <Spinner
                                        thickness='4px'
                                        speed='0.65s'
                                        emptyColor='gray.200'
                                        color='blue.500'
                                        size='xl'
                                    />
                                    :
                                    <Formik
                                        initialValues={{
                                            nombres: client.cNombres,
                                            nombres2: client.cNombres2 || "",
                                            apellidos: client.cApellidoPaterno || "",
                                            apellidos2: client.cApellidoMaterno || "",
                                            documento: client.cDocumento || "",
                                            telefono: client.cTelefono,
                                            email: client.cEmail,
                                            fecha_nacimiento: client.dFechaNacimiento ? new Date(client.dFechaNacimiento).toISOString().split('T')[0] : "",
                                            genero: client.cGenero || '',
                                            zona_residencial: client.cZonaResidencial || "",
                                            departamento: client.cDepartamento || "",
                                            municipio: client.cMunicipio || "",
                                            direccion: client.cDireccion || "",
                                            ingresos: client.nIngresosMensuales || 0,
                                            gastos: client.nGastosMensuales || 0,
                                            montoPrima: client.nMontoPrima || 0,
                                            montoSolicitado: client.nMontoSolicitado || 0,
                                            lugar_trabajo: client.cLugarTrabajo || '',
                                            ocupacion: client.cOcupacion || "",
                                            profesion: client.cProfesion || ""
                                        }}
                                        validate={values => FormValidator([
                                            { field: 'nombres', value: values.nombres, rules: ['required'], lang: 'Primer Nombre' },
                                            { field: 'apellidos', value: values.apellidos, rules: ['required'], lang: 'Apellido Materno' },
                                            { field: 'documento', value: values.documento, rules: ['required'] },
                                            { field: 'telefono', value: values.telefono, rules: ['required'] },
                                            { field: 'email', value: values.email, rules: ['required'] },
                                            { field: 'fecha_nacimiento', value: values.fecha_nacimiento, rules: ['required'] },
                                            { field: 'genero', value: values.genero, rules: ['required'] },
                                            { field: 'zona_residencial', value: values.zona_residencial, rules: ['required'] },
                                            { field: 'departamento', value: values.departamento, rules: ['required'] },
                                            { field: 'municipio', value: values.municipio, rules: ['required'] },
                                            { field: 'direccion', value: values.direccion, rules: ['required'] },
                                            { field: 'ingresos', value: values.ingresos, rules: ['required'] },
                                            { field: 'gastos', value: values.gastos, rules: ['required'] },
                                            { field: 'montoPrima', value: values.montoPrima, rules: ['required'] },
                                            { field: 'montoSolicitado', value: values.montoSolicitado, rules: ['required'] },
                                            { field: 'profesion', value: values.profesion, rules: ['required'] },
                                            { field: 'ocupacion', value: values.ocupacion, rules: ['required'] },
                                            { field: 'lugar_trabajo', value: values.lugar_trabajo, rules: ['required'] },
                                        ])}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            setSubmitting(true)
                                            Promise.resolve(updateClient({ ...values, id: client.id }))
                                                .then(() => setSubmitting(false))
                                        }}
                                    >
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                            <form onSubmit={handleSubmit}>
                                                <HStack>
                                                    <FormControl isInvalid={errors.nombres && touched.nombres}>
                                                        <FormLabel>Primer Nombre</FormLabel>
                                                        <Input type="text" name='nombres' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.nombres} />
                                                        <FormErrorMessage>{errors.nombres}</FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl isInvalid={errors.nombres2 && touched.nombres2}>
                                                        <FormLabel>Segundo Nombre</FormLabel>
                                                        <Input type="text" name='nombres2' size="lg" onChange={handleChange} onBlur={handleBlur} value={values.nombres2} />
                                                        <FormErrorMessage>{errors.nombres2}</FormErrorMessage>
                                                    </FormControl>
                                                </HStack>
                                                <HStack>
                                                    <FormControl isInvalid={errors.apellidos && touched.apellidos}>
                                                        <FormLabel>Apellido Paterno</FormLabel>
                                                        <Input type={'text'} size="lg" name={"apellidos"} onChange={handleChange} onBlur={handleBlur} value={values.apellidos} />
                                                        <FormErrorMessage>{errors.apellidos}</FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl isInvalid={errors.apellidos2 && touched.apellidos2}>
                                                        <FormLabel>Apellido Materno</FormLabel>
                                                        <Input type={'text'} size="lg" name={"apellidos2"} onChange={handleChange} onBlur={handleBlur} value={values.apellidos2} />
                                                        <FormErrorMessage>{errors.apellidos2}</FormErrorMessage>
                                                    </FormControl>
                                                </HStack>

                                                <HStack>
                                                    <FormControl isInvalid={errors.documento && touched.documento}>
                                                        <FormLabel>Documento de identidad</FormLabel>
                                                        <Input type={'text'} size="lg" name={"documento"} onChange={handleChange} onBlur={handleBlur} value={values.documento} />
                                                        <FormErrorMessage>{errors.documento}</FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl isInvalid={errors.telefono && touched.telefono}>
                                                        <FormLabel>Teléfono</FormLabel>
                                                        <Input type={'number'} size="lg" name={"telefono"} onChange={handleChange} onBlur={handleBlur} value={values.telefono} />
                                                        <FormErrorMessage>{errors.telefono}</FormErrorMessage>
                                                    </FormControl>
                                                </HStack>

                                                <FormControl isInvalid={errors.email && touched.email}>
                                                    <FormLabel>Correo</FormLabel>
                                                    <Input type={'email'} size="lg" name={"email"} onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                                                </FormControl>
                                                <HStack>

                                                    <FormControl isInvalid={errors.fecha_nacimiento && touched.fecha_nacimiento}>
                                                        <FormLabel>Fecha de nacimiento</FormLabel>
                                                        <Input type={'date'} size="lg" name={"fecha_nacimiento"} onChange={handleChange} onBlur={handleBlur} value={values.fecha_nacimiento} max="2999-12-31" min={"1900-01-01"} />
                                                        <FormErrorMessage>{errors.fecha_nacimiento}</FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl isInvalid={errors.genero && touched.genero}>
                                                        <FormLabel>Género</FormLabel>
                                                        <Select size="lg" name={"genero"} onChange={handleChange} onBlur={handleBlur} value={values.genero} >
                                                            <option value={""}>Seleccione género</option>
                                                            <option value={"No mencionado"}>No mencionado</option>
                                                            <option value={"Masculino"}> Masculino</option>
                                                            <option value={"Femenino"}> Femenino</option>
                                                        </Select>
                                                        <FormErrorMessage>{errors.genero}</FormErrorMessage>
                                                    </FormControl>
                                                </HStack>



                                                <FormControl isInvalid={errors.zona_residencial && touched.zona_residencial}>
                                                    <FormLabel>Zona residencial</FormLabel>
                                                    <Select size="lg" name={"zona_residencial"} onChange={handleChange} onBlur={handleBlur} value={values.zona_residencial} >
                                                        <option value={""}>Seleccione zona</option>
                                                        <option value={"Zona central"}>Zona central</option>
                                                        <option value={"Zona oriental"}>Zona oriental</option>
                                                        <option value={"Zona occidental"}>Zona occidental</option>
                                                    </Select>
                                                    <FormErrorMessage>{errors.zona_residencial}</FormErrorMessage>
                                                </FormControl>

                                                <HStack>
                                                    <FormControl isInvalid={errors.departamento && touched.departamento}>
                                                        <FormLabel>Departamento</FormLabel>
                                                        <Select size="lg" name={"departamento"} onChange={handleChange} onBlur={handleBlur} value={values.departamento} >
                                                            <option value={""}>Seleccione departamento</option>
                                                            {departamentos.filter(departamento => {
                                                                if (values.zona_residencial === departamento.ZonaResidencial) return true
                                                                return false
                                                            }
                                                            ).map(departamento =>
                                                                <option value={departamento.CodDepartamento} key={departamento.CodDepartamento}> {departamento.Departamento} </option>
                                                            )}
                                                        </Select>
                                                        <FormErrorMessage>{errors.departamento}</FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl isInvalid={errors.municipio && touched.municipio}>
                                                        <Municipio departamentosList={departamentos} fetchEntityWithId={fetchEntityWithId}>
                                                            <FormLabel>Municipio</FormLabel>
                                                            <Select size="lg" name={"municipio"} onChange={handleChange} onBlur={handleBlur} value={values.municipio}  >
                                                                <option value={""}>Seleccione municipio</option>
                                                                {municipios.map(municipio =>
                                                                    <option value={municipio.CodMunicipio} key={municipio.CodMunicipio}> {municipio.Municipio} </option>
                                                                )}
                                                            </Select>
                                                            <FormErrorMessage>{errors.municipio}</FormErrorMessage>
                                                        </Municipio>
                                                    </FormControl>
                                                </HStack>
                                                <FormControl isInvalid={errors.direccion && touched.direccion}>
                                                    <FormLabel>Dirección</FormLabel>
                                                    <Textarea size="lg" name={"direccion"} onChange={handleChange} onBlur={handleBlur} value={values.direccion} />
                                                    <FormErrorMessage>{errors.direccion}</FormErrorMessage>
                                                </FormControl>

                                                <HStack>
                                                    <FormControl isInvalid={errors.ingresos && touched.ingresos}>
                                                        <FormLabel>Ingresos mensuales</FormLabel>
                                                        <Input type={'number'} size="lg" name={"ingresos"} min={0} step={"0.01"} onChange={handleChange} onBlur={handleBlur} value={values.ingresos} />
                                                        <FormErrorMessage>{errors.ingresos}</FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl isInvalid={errors.gastos && touched.gastos}>
                                                        <FormLabel>Gastos mensuales</FormLabel>
                                                        <Input type={'number'} size="lg" name={"gastos"} min={0} step={"0.01"} onChange={handleChange} onBlur={handleBlur} value={values.gastos} />
                                                        <FormErrorMessage>{errors.gastos}</FormErrorMessage>
                                                    </FormControl>
                                                </HStack>

                                                <HStack>
                                                    <FormControl isInvalid={errors.lugar_trabajo && touched.lugar_trabajo}>
                                                        <FormLabel>Lugar de trabajo</FormLabel>
                                                        <Input type={'text'} size="lg" name={"lugar_trabajo"} onChange={handleChange} onBlur={handleBlur} value={values.lugar_trabajo} />
                                                        <FormErrorMessage>{errors.lugar_trabajo}</FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl isInvalid={errors.profesion && touched.profesion}>
                                                        <FormLabel>Profesión</FormLabel>
                                                        <Select size="lg" name={"profesion"} onChange={handleChange} onBlur={handleBlur} value={values.profesion} >
                                                            <option value={""}>Seleccione Profesión</option>
                                                            <option value={"EMPLEADO DEL SECTOR PRIVADO"}>EMPLEADO DEL SECTOR PRIVADO</option>
                                                            <option value={"EMPLEADO DEL SECTOR PÚBLICO"}>EMPLEADO DEL SECTOR PÚBLICO</option>
                                                            <option value={"EMPLEADOS CON UN CONTRATO TEMPORAL"}>EMPLEADOS CON UN CONTRATO TEMPORAL</option>
                                                            <option value={"COMERCIANTE INDIVIDUAL CONSTITUIDO"}>COMERCIANTE INDIVIDUAL CONSTITUIDO</option>
                                                            <option value={"CONTRATISTAS /TÉCNICOS INDEPENDIENTES"}>CONTRATISTAS /TÉCNICOS INDEPENDIENTES</option>
                                                            <option value={"PROFESIONALES INDEPENDIENTES"}>PROFESIONALES INDEPENDIENTES</option>
                                                            <option value={"COMERCIANTE INFORMAL"}>COMERCIANTE INFORMAL</option>
                                                            <option value={"BENEFICIARIO REMESAS"}>BENEFICIARIO REMESAS</option>
                                                            <option value={"JUBILADOS & PENSIONADOS"}>JUBILADOS & PENSIONADOS</option>
                                                            <option value={"ARRENDAMIENTO"}>ARRENDAMIENTO</option>
                                                        </Select>
                                                        <FormErrorMessage>{errors.profesion}</FormErrorMessage>
                                                    </FormControl>
                                                </HStack>

                                                <FormControl isInvalid={errors.ocupacion && touched.ocupacion}>
                                                    <FormLabel>Ocupación</FormLabel>
                                                    <Input type={'text'} size="lg" name={"ocupacion"} onChange={handleChange} onBlur={handleBlur} value={values.ocupacion} />
                                                    <FormErrorMessage>{errors.ocupacion}</FormErrorMessage>
                                                </FormControl>

                                                <HStack>
                                                    <FormControl isInvalid={errors.montoPrima && touched.montoPrima}>
                                                        <FormLabel>Monto de prima</FormLabel>
                                                        <Input type={'number'} size="lg" name={"montoPrima"} min={0} step={"0.01"} onChange={handleChange} onBlur={handleBlur} value={values.montoPrima} />
                                                        <FormErrorMessage>{errors.montoPrima}</FormErrorMessage>
                                                    </FormControl>

                                                    <FormControl isInvalid={errors.montoSolicitado && touched.montoSolicitado}>
                                                        <FormLabel>Monto solicitado</FormLabel>
                                                        <Input type={'number'} size="lg" name={"montoSolicitado"} min={0} step={"0.01"} onChange={handleChange} onBlur={handleBlur} value={values.montoSolicitado} />
                                                        <FormErrorMessage>{errors.montoSolicitado}</FormErrorMessage>
                                                    </FormControl>
                                                </HStack>


                                                <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                    {isSubmitting ? <CircularProgress /> : 'Actualizar'}
                                                </Button>
                                            </form>
                                        )}
                                    </Formik>
                                }

                            </TabPanel>

                            <TabPanel>
                                {solicitudes.lenght === 0 ?
                                    "No hay solicitudes del usuario"
                                    :
                                    <Table>
                                        <Thead>
                                            <Tr>
                                                <Th>Fecha</Th>
                                                <Th>Estado</Th>
                                                <Th>Etapa</Th>
                                                <Th>Detalle</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {solicitudes.map(solicitud =>
                                                <Tr key={solicitud.id}>
                                                    <Td>{new Date(solicitud.dFechaCreacion).toISOString().split('T')[0]} </Td>
                                                    <Td>{
                                                        financiamiento_estados.reduce((text, estado) => {
                                                            if (estado.id === solicitud.cEstadoFinanciamiento) return estado.nombre
                                                            return text
                                                        }, 'En espera de análisis')
                                                    }</Td>
                                                    <Td>{solicitud.cEtapa || 'En Proceso'}</Td>
                                                    <Td>
                                                        <Link to={`/solicitud/${solicitud.id}/view`}><Button colorScheme="teal" variant="outline">Detalle</Button></Link>
                                                    </Td>
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </Table>
                                }
                            </TabPanel>

                            <TabPanel>
                                <Text>Dui</Text>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/1`} align={'center'} w={'auto'} h={'300px'} onError={replaceImage} />
                                <br />
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/2`} align={'center'} w={'auto'} h={'300px'} onError={replaceImage} />
                                <Text mt={5} >NIT</Text>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/3`} align={'center'} w={'auto'} h={'300px'} onError={replaceImage} />
                                <Text mt={5} >Autorización buro</Text>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/4`} align={'center'} w={'auto'} h={'300px'} onError={replaceImage} />
                                <Text mt={5} >Comprobante de ingresos</Text>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/5`} align={'center'} w={'auto'} h={'300px'} onError={replaceImage} />
                                <Text mt={5} >Licencia</Text>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/6`} align={'center'} w={'auto'} h={'300px'} onError={replaceImage} />
                                <Text mt={5} >Solicitud de préstamo</Text>
                                <Image alt={`Preview`} src={`${APIpath}/cliente/document/image/${client.id}/7`} align={'center'} w={'auto'} h={'300px'} onError={replaceImage} />
                            </TabPanel>

                            <TabPanel>
                                {bandeja !== null &&
                                    <Box>
                                        <HStack>
                                            <FormControl>
                                                <FormLabel>Asesor: </FormLabel>
                                                <Input readOnly variant='flushed' value={bandeja.asesor} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>N* Solicitud: </FormLabel>
                                                <Input readOnly variant='flushed' value={client.id} />
                                            </FormControl>
                                        </HStack>
                                        <HStack>
                                            <FormControl>
                                                <FormLabel>Nombre del cliente: </FormLabel>
                                                <Input readOnly variant='flushed' value={`${client.cNombres} ${client.cNombres2 || ""} ${client.cApellidoPaterno} ${client.cApellidoMaterno}`} />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Código del cliente: </FormLabel>
                                                <Input readOnly variant='flushed' value={client.id + client.cCodigo} />
                                            </FormControl>
                                        </HStack>

                                        <HStack>
                                            {
                                                bandeja.created_at !== null &&
                                                <FormControl>
                                                    <FormLabel>Fecha de asignación: </FormLabel>
                                                    <Input readOnly variant='flushed' value={new Date(bandeja.created_at).toISOString().split('T')[0]} />
                                                </FormControl>
                                            }
                                            {bandeja.fecha_accion !== null &&
                                                <FormControl>
                                                    <FormLabel>Fecha de Acción: </FormLabel>
                                                    <Input readOnly variant='flushed' value={new Date(bandeja.fecha_accion).toISOString().split('T')[0]} />
                                                </FormControl>
                                            }
                                        </HStack>

                                        <HStack>
                                            {
                                                bandeja.updated_at !== null &&
                                                <FormControl>
                                                    <FormLabel>Fecha de ultimo cambio: </FormLabel>
                                                    <Input readOnly variant='flushed' value={bandeja.updated_at ? new Date(bandeja.updated_at).toISOString().split('T')[0] : ""} />
                                                </FormControl>
                                            }
                                            {bandeja.fecha_accion !== null &&
                                                <FormControl>
                                                    <FormLabel>Tiempo de respuesta: </FormLabel>
                                                    <Input readOnly variant='flushed' value={this.tiempo_espera(bandeja.created_at, bandeja.fecha_accion)} />
                                                </FormControl>
                                            }
                                        </HStack>

                                        <FormControl>
                                            <FormLabel>Estado del cliente</FormLabel>
                                            <Input Input readOnly variant='flushed' value={bandeja.estado || 'Pendiente'} />
                                        </FormControl>
                                        <Formik
                                            initialValues={{
                                                resultado: bandeja.bandeja_accion_id || '',
                                                observaciones: bandeja.observaciones || ''
                                            }}
                                            validate={values => FormValidator([
                                                { field: 'resultado', value: values.resultado, rules: ['required'] },
                                                { field: 'observaciones', value: values.observaciones, rules: ['required'] }
                                            ])}
                                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                                Promise.resolve(updateBandejaCliente({ ...values, id: bandeja.id }))
                                                    .then(() => {
                                                        setSubmitting(false)
                                                        resetForm()
                                                    })
                                            }}
                                        >
                                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <FormControl isInvalid={errors.resultado && touched.resultado}>
                                                        <FormLabel>Resultado del cliente</FormLabel>
                                                        <Select size="lg" name={"resultado"} onChange={handleChange} onBlur={handleBlur} value={values.resultado} >
                                                            <option value={""}>Seleccione resultado del cliente</option>
                                                            {typeof bandeja_acciones !== 'undefined' && bandeja_acciones.map(bandeja_accion =>
                                                                <option value={bandeja_accion.id} key={bandeja_accion.id}>{bandeja_accion.nombre}</option>
                                                            )}
                                                        </Select>
                                                        <FormErrorMessage>{errors.resultado}</FormErrorMessage>
                                                    </FormControl>
                                                    <FormControl isInvalid={errors.observaciones && touched.observaciones}>
                                                        <FormLabel>Observaciones</FormLabel>
                                                        <Textarea size="lg" name={"observaciones"} onChange={handleChange} onBlur={handleBlur} value={values.observaciones} />
                                                        <FormErrorMessage>{errors.observaciones}</FormErrorMessage>
                                                    </FormControl>
                                                    <Button colorScheme="teal" variant="outline" type="submit" mt={4} disabled={isSubmitting}>
                                                        {isSubmitting ? <CircularProgress /> : 'Guardar'}
                                                    </Button>
                                                </form>
                                            )}
                                        </Formik>

                                    </Box>
                                }

                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                </Box>
            </Layout>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: getConfig(state),
    financiamiento_estados: getEntity(state, 'financiamiento'),
    departamentos: getEntity(state, 'departamento'),
    municipios: getEntity(state, 'municipio'),
    bandeja_acciones: getEntity(state, 'bandeja'),
    client: getClient(state, ownProps.match.params.id),
    bandeja: getBandejaUsuario(state, ownProps.match.params.id),
    solicitudes: getSolicitudesCliente(state, ownProps.match.params.id)
})

const mapDispatchToProps = { updateClient, fetchClient, fetchEntity, fetchEntityWithId, fetchBandejaCliente, fetchSolicitudesCliente, updateBandejaCliente, carImage }

export default connect(mapStateToProps, mapDispatchToProps)(ClientEdit)
